<template>

    <v-content>
        <v-breadcrumbs :items="items" large></v-breadcrumbs>
        <v-dialog v-model="dialog" max-width="60%">
            <!--<template v-slot:activator="{ on }">
                <v-btn color="primary" dark class="mb-2 float-right" @click="bulkSubmit($event.target)">
                    <i class="material-icons add-icon-m">
                        cloud_upload
                    </i>Bulk Upload
                </v-btn>
                <v-btn color="primary" dark class="mb-2 ml-2 float-right" v-on="on">
                    <i class="material-icons add-icon-m">
                        add_circle_outline
                    </i>User Info
                </v-btn>
            </template>-->
            <v-card flat>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <form>
                            <v-row>

                                <v-col cols="12" md="6">
                                    <v-autocomplete :items="countries" :filter="customFilter" color="black"
                                                    item-text="name" v-model="editedUser.countryofResidence"
                                                    :error-messages="countryofResidenceErrors"
                                                    label="Country of Residence" required
                                                    @input="$v.editedUser.countryofResidence.$touch()"
                                                    @blur="$v.editedUser.countryofResidence.$touch()"></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editedUser.passportNumber"
                                                  :error-messages="passportNumberErrors" label="Passport Number"
                                                  required @input="$v.editedUser.passportNumber.$touch()"
                                                  @blur="$v.editedUser.passportNumber.$touch()"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-menu :close-on-content-click="false" v-model="datePickerArrivalDate"
                                            transition="scale-transition" offset-y :nudge-right="40" max-width="290px"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="editedUser.dateofArrival"
                                                          :error-messages="dateofArrivalErrors" label="Date of Arrival"
                                                          required @input="$v.editedUser.dateofArrival.$touch()"
                                                          @blur="$v.editedUser.dateofArrival.$touch()" slot="activator"
                                                          v-on="on" prepend-icon="event" readonly></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedUser.dateofArrival" color="green lighten-1"
                                                       header-color="primary" no-title scrollable actions>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-menu :close-on-content-click="false" v-model="datePickerDepartureDate"
                                            transition="scale-transition" offset-y :nudge-right="40" max-width="290px"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="editedUser.dateofDeparture"
                                                          :error-messages="dateofDepartureErrors"
                                                          label="Date of Departure" required
                                                          @input="$v.editedUser.dateofDeparture.$touch()"
                                                          @blur="$v.editedUser.dateofDeparture.$touch()"
                                                          slot="activator" v-on="on" prepend-icon="event"
                                                          readonly></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedUser.dateofDeparture" color="green lighten-1"
                                                       header-color="primary" no-title scrollable actions>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-menu :close-on-content-click="false" v-model="datePickerIssueDate"
                                            transition="scale-transition" offset-y :nudge-right="40" max-width="290px"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="editedUser.visaIssueDate"
                                                          :error-messages="visaIssueDateErrors" label="Visa Issue Date"
                                                          required @input="$v.editedUser.visaIssueDate.$touch()"
                                                          @blur="$v.editedUser.visaIssueDate.$touch()" slot="activator"
                                                          v-on="on" prepend-icon="event"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedUser.visaIssueDate" color="green lighten-1"
                                                       header-color="primary" no-title scrollable actions>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-menu :close-on-content-click="false" v-model="datePickerDOB"
                                            transition="scale-transition" offset-y :nudge-right="40" max-width="290px"
                                            min-width="290px">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field v-model="editedUser.dateofBirth"
                                                          :error-messages="dateofBirthErrors" label="Date of Birth"
                                                          required @input="$v.editedUser.dateofBirth.$touch()"
                                                          @blur="$v.editedUser.dateofBirth.$touch()" slot="activator"
                                                          v-on="on" prepend-icon="event"></v-text-field>
                                        </template>
                                        <v-date-picker v-model="editedUser.dateofBirth" color="green lighten-1"
                                                       header-color="primary" no-title scrollable actions>
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-select v-model="editedUser.gender" :items="genders"
                                              :error-messages="genderErrors" label="Gender" required
                                              @input="$v.editedUser.gender.$touch()"
                                              @blur="$v.editedUser.gender.$touch()"></v-select>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editedUser.portofEntry" :error-messages="portofEntryErrors"
                                                  label="Port of Entry" required
                                                  @input="$v.editedUser.portofEntry.$touch()"
                                                  @blur="$v.editedUser.portofEntry.$touch()"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editedUser.contactDetails"
                                                  :error-messages="contactDetailsErrors" label="Contact Details"
                                                  required @input="$v.editedUser.contactDetails.$touch()"
                                                  @blur="$v.editedUser.contactDetails.$touch()"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field v-model="editedUser.purposeofVisit"
                                                  :error-messages="purposeofVisitErrors" label="Purpose of Visit"
                                                  required @input="$v.editedUser.purposeofVisit.$touch()"
                                                  @blur="$v.editedUser.purposeofVisit.$touch()"></v-text-field>
                                </v-col>
                                <v-btn class="mr-4" @click="submit" color="primary">submit</v-btn>
                                <v-btn @click="clear">clear</v-btn>
                            </v-row>
                        </form>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-expansion-panels hover>
            <v-expansion-panel>
                <v-expansion-panel-header>Filter</v-expansion-panel-header>
                <v-expansion-panel-content>
                    <v-container fluid>
                        <v-row align="start">
                            <v-col class="d-flex" cols="12" sm="3">
                                <v-autocomplete :items="countries" :filter="customFilter" color="black" item-text="name"
                                                v-model="editedUser.countryofResidence" label="Country of Residence"
                                                required></v-autocomplete>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="2">
                                <v-menu :close-on-content-click="false" v-model="datePickerArrivalDate"
                                        transition="scale-transition" offset-y :nudge-right="40" max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="editedUser.dateofArrival" slot="activator" v-on="on"
                                                      prepend-icon="event" label="Date of Arrival"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedUser.dateofArrival" color="green lighten-1"
                                                   header-color="primary" no-title scrollable actions>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="2">
                                <v-menu :close-on-content-click="false" v-model="datePickerDepartureDate"
                                        transition="scale-transition" offset-y :nudge-right="40" max-width="290px"
                                        min-width="290px">
                                    <template v-slot:activator="{ on }">
                                        <v-text-field v-model="editedUser.dateofDeparture" slot="activator" v-on="on"
                                                      prepend-icon="event" label="Date of Departure"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="editedUser.dateofDeparture" color="green lighten-1"
                                                   header-color="primary" no-title scrollable actions>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="d-flex" cols="12" sm="3">
                                <v-select v-model="editedUser.gender" :items="genders" label="Gender"
                                          required></v-select>
                            </v-col>
                            <!--                            <v-col class="float-right" cols="12" sm="2">-->
                            <!--                                <v-text-field label="Seartch input" v-model="serverParams.search"></v-text-field>-->
                            <!--                            </v-col>-->

                            <v-col class="float-right" cols="12" sm="2">
                                <v-tooltip right>
                                    <template v-slot:activator="{ on }">
                                        <v-btn class="ma-2 white--text" color="primary"><i class="material-icons"
                                                                                           v-on="on" @click="filter">search</i>
                                        </v-btn>
                                    </template>
                                    <span>Filter</span>
                                </v-tooltip>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-row>
            <v-col cols="12" sm="2" offset="9">
                <v-file-input v-model="formFile" show-size label="File input"></v-file-input>
            </v-col>
            <v-col cols="12" sm="1" class="float-right">
                <v-btn color="primary" v-on:click="submitFile()">Import</v-btn>
            </v-col>
        </v-row>
        <vue-good-table mode="remote"
                        @on-page-change="onPageChange"
                        @on-sort-change="onSortChange"
                        @on-column-filter="onColumnFilter"
                        @on-per-page-change="onPerPageChange"
                        :totalRows="totalRecords"
                        :isLoading.sync="isLoading"
                        :pagination-options="{ enabled: true, }"
                        :rows="rows"
                        :columns="columns"
                        theme="black-rhino">
            <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
                <v-icon small class="mr-2" @click="editItem(props.row)">edit</v-icon>
                <v-icon small @click="deleteItem(props.row)">delete</v-icon>
        </span>
                <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
            </template>
        </vue-good-table>
    </v-content>
</template>

<script>
    import axios from 'axios'
    import router from "@/routes/router";
    import {required} from 'vuelidate/lib/validators'
    import countries from '../../assets/json/countries'
    import immigrationData from '../../assets/json/immigration'

    export default {
        name: 'List',
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? 'User Entry Form' : 'Edit User'
            },
            countryofResidenceErrors() {
                const errors = []
                if (!this.$v.editedUser.countryofResidence.$dirty) return errors
                !this.$v.editedUser.countryofResidence.required && errors.push('Countery of Residence is required.')
                return errors
            },
            passportNumberErrors() {
                const errors = []
                if (!this.$v.editedUser.passportNumber.$dirty) return errors
                !this.$v.editedUser.passportNumber.required && errors.push('Last Name is required.')
                return errors
            },
            dateofArrivalErrors() {
                const errors = []
                if (!this.$v.editedUser.dateofArrival.$dirty) return errors
                !this.$v.editedUser.dateofArrival.required && errors.push('User Name is required.')
                return errors
            },
            dateofDepartureErrors() {
                const errors = []
                if (!this.$v.editedUser.dateofDeparture.$dirty) return errors
                !this.$v.editedUser.dateofDeparture && errors.push('Date of Departure is required')
                return errors
            },
            genderErrors() {
                const errors = []
                if (!this.$v.editedUser.gender.$dirty) return errors
                !this.$v.editedUser.gender.required && errors.push('Gender is required.')
                return errors
            },
            dateofBirthErrors() {
                const errors = []
                if (!this.$v.editedUser.dateofBirth.$dirty) return errors
                !this.$v.editedUser.dateofBirth.required && errors.push('Date of Birth is required.')
                return errors
            },
            portofEntryErrors() {
                const errors = []
                if (!this.$v.editedUser.portofEntry.$dirty) return errors
                !this.$v.editedUser.portofEntry.required && errors.push('Port of Entry is required.')
                return errors
            },
            contactDetailsErrors() {
                const errors = []
                if (!this.$v.editedUser.contactDetails.$dirty) return errors
                !this.$v.editedUser.contactDetails.required && errors.push('Contact Details is required.')
                return errors
            },
            visaIssueDateErrors() {
                const errors = []
                if (!this.$v.editedUser.visaIssueDate.$dirty) return errors
                !this.$v.editedUser.visaIssueDate.required && errors.push('Visa Issue Date is required.')
                return errors
            },
            purposeofVisitErrors() {
                const errors = []
                if (!this.$v.editedUser.purposeofVisit.$dirty) return errors
                !this.$v.editedUser.purposeofVisit.required && errors.push('Contact Details is required.')
                return errors
            },
        },
        watch: {
            dialog(val) {
                val || this.close()
            },
        },
        components: {},
        data() {
            return {
                formFile: '',
                uploadPercentage: 0,
                dialog: false,
                dialog2: false,
                datePickerDOB: false,
                datePickerArrivalDate: false,
                datePickerDepartureDate: false,
                datePickerIssueDate: false,
                options: ['Foo', 'Bar', 'Fizz', 'Buzz'],
                genders: ['Male', 'Female', 'others'],
                countries: countries,
                items: [],
                popout: true,
                isLoading: false,
                columns: [
                    {
                        label: 'Country of Residence',
                        field: 'Country of Residence'
                    },
                    {
                        label: 'Passport Number',
                        field: 'Passport Number'
                    },
                    {
                        label: 'Date of Arrival',
                        field: 'Date of Arrival'
                    },
                    {
                        label: 'Date of Departure',
                        field: 'Date of Departure'
                    },
                    {
                        label: 'Gender',
                        field: 'Gender'
                    },

                    {
                        label: 'Date of Birth',
                        field: 'Date of Birth'
                    },

                    {
                        label: 'Port of Entry',
                        field: 'Port of Entry'
                    },

                    {
                        label: 'Contact Details',
                        field: 'Contact Details'
                    },

                    {
                        label: 'Visa Issue Date',
                        field: 'Visa Issue Date'
                    },
                    {
                        label: 'Actions',
                        field: 'actions',
                        sortable: false,
                    }
                ],
                rows: [],
                totalRecords: 0,
                serverParams: {
                    search: '',
                    columnFilters: {},
                    sort: [{
                        field: 'userID',
                        type: 'asc',
                    }],
                    page: 1,
                    perPage: 10
                },
                user: [],
                editedIndex: -1,
                editedUser: {
                    countryofResidence: '',
                    passportNumber: '',
                    dateofArrival: '',
                    dateofDeparture: '',
                    gender: '',
                    dateofBirth: '',
                    portofEntry: '',
                    contactDetails: '',
                    visaIssueDate: '',
                    purposeofVisit: ''
                },
                defaultUser: {
                    countryofResidence: '',
                    passportNumber: '',
                    dateofDeparture: '',
                    dateofArrival: '',
                    gender: '',
                    dateofBirth: '',
                    portofEntry: '',
                    contactDetails: '',
                    visaIssueDate: '',
                    purposeofVisit: ''
                },
            };
        },
        validations: {
            editedUser: {
                countryofResidence: {required},
                passportNumber: {required},
                dateofArrival: {required},
                dateofDeparture: {required},
                gender: {required},
                dateofBirth: {required},
                portofEntry: {required},
                contactDetails: {required},
                visaIssueDate: {required},
                purposeofVisit: {required}
            },
        },
        mounted() {
            this.loadItems()
        },
        methods: {
            customFilter(item, queryText) {
                const textOne = item.name.toLowerCase()
                const textTwo = item.code.toLowerCase()
                const searchText = queryText.toLowerCase()

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1
            },
            handleFileUpload() {
                this.excelFile = this.$refs.file.files[0];
                // supported excel "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.oasis.opendocument.spreadsheet";
                let fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                if (this.excelFile.type != fileType) {
                    alert("Invalid file format");
                    this.excelFile = '';
                    return false;
                }
            },
            submitFile() {
                let formData = new FormData()
                formData.append('formFile', this.formFile);
                console.log(formData.getAll('formFile'))
                axios.post('Division/import',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        },
                        onUploadProgress: function (progressEvent) {
                            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                        }.bind(this)
                    }
                ).then(function () {
                    console.log('SUCCESS!!');
                })
                    .catch(function () {
                        console.log('FAILURE!!');
                    });

            },
            filter() {
                let filterParam = {
                    "countryofResidence": this.editedUser.countryofResidence,
                    "dateofDeparture": this.editedUser.dateofDeparture,
                    "dateofArrival": this.editedUser.dateofArrival,
                    "gender": this.editedUser.gender
                }
                console.log("filter code here", filterParam)
            },
            submit() {
                this.$v.$touch()
                if (this.$v.$invalid) {
                    this.submitStatus = 'ERROR'
                } else {
                    let param = {
                        "countryofResidence": this.editedUser.countryofResidence,
                        "BranchID": 1,
                        "passportNumber": this.editedUser.passportNumber,
                        "dateofDeparture": this.editedUser.dateofDeparture,
                        "dateofArrival": this.editedUser.dateofArrival,
                        "gender": this.editedUser.gender
                    }
                    axios.post('User/CreateUser', param)
                    this.submitStatus = 'PENDING'
                    this.dialog = false
                    this.loadItems();
                    setTimeout(() => {
                        this.submitStatus = 'OK'
                    }, 500)
                }
            },
            clear() {
                this.$v.$reset()
                this.editedUser.countryofResidence = ''
                this.editedUser.passportNumber = '',
                    this.editedUser.dateofArrival = '',
                    this.editedUser.dateofDeparture = '',
                    this.editedUser.gender = '',
                    this.editedUser.dateofBirth = '',
                    this.editedUser.portofEntry = '',
                    this.editedUser.contactDetails = '',
                    this.editedUser.visaIssueDate = '',
                    this.editedUser.purposeofVisit = ''
            },

            editItem(props) {
                console.log(props)
                this.editedIndex = this.user.indexOf(props)
                this.editedUser = Object.assign({}, props)
                this.dialog = true
            },

            deleteItem(item) {
                const index = this.user.indexOf(item)
                confirm('Are you sure you want to delete this item?') && this.user.splice(index, 1)
            },

            close() {
                this.dialog = false
                setTimeout(() => {
                    this.editedUser = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                }, 300)
            },
            updateParams(newProps) {
                this.serverParams = Object.assign({}, this.serverParams, newProps);
            },

            searchInput() {
                this.loadItems();
            },

            onPageChange(params) {
                this.updateParams({page: params.currentPage});
                this.loadItems();
            },

            onPerPageChange(params) {
                this.updateParams({perPage: params.currentPerPage==-1?this.totalRecords:params.currentPerPage});
                this.loadItems();
            },

            onSortChange(params) {
                this.updateParams({
                    sort: [{
                        type: params[0].type,
                        field: params[0].field,
                    }],
                });
                this.loadItems();
            },

            onColumnFilter(params) {
                this.updateParams(params);
                this.loadItems();
            },

            // load items is what brings back the rows from server
            async loadItems() {
                // eslint-disable-next-line no-console
                if (this.totalRecords < this.serverParams.perPage) {
                    this.serverParams.perPage = 10
                    this.serverParams.page = 1
                }
                let param = {
                    "PageSize": this.serverParams.perPage,
                    "PageNo": this.serverParams.page,
                    "OrderType": this.serverParams.sort[0].type,
                    "OrderBy": this.serverParams.sort[0].field,
                    "Name": this.serverParams.search
                }
                try {
                    let list = await axios.post('User/UserList', param)
                    this.rows = immigrationData
                    this.totalRecords = list.data.totalCount
                } catch (error) {
                    localStorage.removeItem('token')
                    router.push('/homepage')
                }
                return
            }
        }
    };
</script>

<style lang="scss" scoped>

    .v-item-group {
        background: #d2e3ff73
    }

    .v-expansion-panel-header {
        background: #d2e3ff73
    }

    .add-icon-m {
        padding-right: 0.3em;
    }

    .file {
        width: 12%;
    }
</style>
